
























import {Component, Prop, Vue} from 'vue-property-decorator'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'

@Component({})
export default class NftCollectionVisibleToggleButton extends Vue {
  @Prop({type: Object, required: true}) collection!: NftCollectionCollection

  value = true

  mounted() {
    this.value = this.collection.isVisible ?? true
  }

  changeValue() {
    this.value = !this.value

    this.collection.isVisible = this.value

    this.$emit('filter', this.value)
  }
}









































































































































import {Vue} from 'vue-property-decorator'
import {ListNftCollectionSchema} from '@/schema/resource/NftCollection/ListNftCollectionSchema'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import Component from 'vue-class-component'
import NftCollectionModal from '@/components/NftCollectionModal.vue'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterNftCollection from '@/components/filters/FilterNftCollection.vue'
import {$} from '@/facade'
import {NftCollection} from '@/model/resource/NftCollection'
import {InputCheckbox} from '@simpli/vue-input'

@Component({
  components: {
    FilterNftCollection,
    FilterToggle,
    NftCollectionModal,
    InputCheckbox,
  },
})
export default class ListNftView extends Vue {
  schema = new ListNftCollectionSchema()
  collection = new NftCollectionCollection()
  filterOpen = false

  async mounted() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.orderBy = 'idContractPk'
    this.collection.isVisible = true
    this.collection.asc = true
    this.collection.minOrder = 0
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await $.await.run('hardQuery', () => Promise.all(promises))
  }

  async changeVisible(item: NftCollection) {
    await item.changeIsVisible()
    $.toast.success('system.success.persist')
    await this.populateResources()
  }

  async changeOrder(item: NftCollection, increment: number) {
    if (item.order === null) {
      item.order = 0
    }

    item.order += increment
    await item.changeOrder()
    $.toast.success('system.success.persist')
    await this.populateResources()
  }
}

import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {INftCollectionCollectionResourceHolder} from '@/model/collection/NftCollectionCollection'
import {Dapp} from '@/model/resource/Dapp'
import {CategoryCollection} from '@/model/collection/CategoryCollection'
import {NntTagCollection} from '@/model/collection/NntTagCollection'
import {WalletCollection} from '@/model/collection/WalletCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {$} from '@/facade'
import {EnvHelper} from '@/helpers/EnvHelper'

export class FilterNftCollectionSchema extends DefaultSchema
  implements INftCollectionCollectionResourceHolder {
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()

  readonly name = 'FilterNftCollection'

  readonly fieldSet: FieldSet<Dapp> = {
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        hideSelected: false,
        multiple: false,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
        class: {hidden: EnvHelper.VUE_APP_N3_HIDE},
      },
    }),
  }

  get blockchainVersionItems(): BlockchainVersion[] {
    const anyVersion = new BlockchainVersion()
    anyVersion.title = $.t('schema.FilterDapp.anyVersion') as string

    return [anyVersion, ...this.collectionBlockchainVersion.items]
  }
}

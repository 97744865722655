
































































































import _ from 'lodash'
import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputSelect} from '@simpli/vue-input'
import FilterTransactionView from '@/components/filters/FilterTransactionView.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import FilterToggle from '@/components/FilterToggle.vue'
import {$} from '@/facade'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import NftCollectionVisibleToggleButton from '@/components/NftCollectionVisibleToggleButton.vue'
import {FilterNftCollectionSchema} from '@/schema/resource/NftCollection/FilterNftCollectionSchema'
import FilterNftCollectionMobile from '@/components/filters/FilterNftCollectionMobile.vue'

@Component({
  components: {
    FilterNftCollectionMobile,
    NftCollectionVisibleToggleButton,
    FilterToggle,
    FilterTransactionView,
    InputSelect,
    Modal,
  },
})
export default class FilterNftCollection extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: NftCollectionCollection

  showModal: boolean = false
  schema = new FilterNftCollectionSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    const promises: Array<Promise<any>> = [
      this.schema.collectionBlockchainVersion.queryAsPage(),
    ]

    await $.await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await $.await.run('softQuery', () => this.collection.querySearch())
  }

  inputClass(field: string): string {
    if (field !== 'blockchainVersion') {
      return 'multiselect--hide-tags hidden md:block'
    }
    return 'multiselect--hide-tags-round-selector hidden md:block'
  }

  toggleModal(): void {
    this.showModal = !this.showModal
  }
}







































































import {Modal} from '@simpli/vue-modal'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {DappConnection} from '@/model/resource/DappConnection'
import {PlatformType} from '@/model/resource/PlatformType'
import {$} from '@/facade'
import NftCollectionVisibleToggleButton from '@/components/NftCollectionVisibleToggleButton.vue'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import {FilterNftCollectionSchema} from '@/schema/resource/NftCollection/FilterNftCollectionSchema'

@Component({
  components: {
    NftCollectionVisibleToggleButton,
    Modal,
    InputCheckbox,
    InputText,
  },
})
export default class FilterNftCollectionMobile extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) collection!: NftCollectionCollection

  schema = new FilterNftCollectionSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    const promises: Array<Promise<any>> = [
      this.schema.collectionBlockchainVersion.queryAsPage(),
    ]

    await $.await.run('softQuery', () => Promise.all(promises))
  }

  hasValue(list = [], value: number) {
    return list.some((item: never) => item === value)
  }

  addVersion(param: number) {
    this.collection.idBlockchainVersionFk = param || null
  }

  async doFilter() {
    this.$emit('submit')
    await $.await.run('softQuery', () => this.collection.queryAsPage())
  }

  doClear() {
    this.collection.blockchainVersion = null
    this.collection.search = ''
    this.doFilter()
  }
}

import { render, staticRenderFns } from "./ListNftView.vue?vue&type=template&id=040d6e1c&scoped=true"
import script from "./ListNftView.vue?vue&type=script&lang=ts"
export * from "./ListNftView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040d6e1c",
  null
  
)

export default component.exports